import React from 'react';
import LandingPageView2 from 'views/LandingPageView2';
import Main from 'layouts/Main';
import WithLandingPageLayout from 'WithLandingPageLayout';
import { Helmet } from 'react-helmet';

// DATA

const title = 'Amazon Storage Reseller Quotes';
const content = {
  hero: {
    title: "Let us do the work for you to get",
    emphasized: "Amazon Storage Reseller Quotes.",
    subtitle: "There is a better way to shop for Amazon storage.",
    titleVariant: "h2",
    subtitleVariant: "h4",      
  },
  quoteForm: {
    quote_provider:"aws",
    title: "Save 60% or more on Amazon storage pricing from leading partners",  
    subtitle: "Let managed service providers bid for your business",
    titleVariant: "h4",
    subtitleVariant: "h5",
    refPage: "aws/amazon-storage-partners",  
  },
 subText: {
    title: "Amazon Storage Pricing",
    emphasized: "Simplified.",
    subtitle: "Your time is important, we can help you reduce AWS S3 storage costs by providing you with competitive quotes from Managed Service Providers and Amazon partners which are up to 60% lower than AWS pricing. Request a quote from our pricing engine for AWS S3 cloud storage today.",
    titleVariant: "h5",
    subtitleVariant: "h6",     
  }
};

// END DATA

const LandingPage = () => {
  return (
  <>
  <Helmet
    defaultTitle="WindRate | #1 Cloud Storage Pricing Engine"
    title={title}
    titleTemplate="%s | WindRate.com"
  />     
    <WithLandingPageLayout
      data={content}
      component={LandingPageView2}
      layout={Main}
    />
    </>
  )
};
 
 export default LandingPage;
 